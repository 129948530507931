import Vue from 'vue'
import axios from 'axios'

const axiosIns = axios.create()

axiosIns.interceptors.request.use( async (axiosConfig) => {
  axiosConfig.baseURL = process.env.VUE_APP_BACKEND_API || '/';
  // if ($IS_DEV) {
  // axiosConfig.headers['Access-Control-Allow-Origin'] = "*";
  // }
  return axiosConfig;
})

Vue.prototype.$http = axiosIns

export default axiosIns
